import * as d3 from "d3";
import _L from "../../util/Labels";
import { UIUtilDialPhoneCodes } from "../util/DialPhoneCodes";
import { UIUtilFormat } from "../util/Format";
import { UIUtilLang } from "../util/Language";
import { UIUtilMask } from "../util/Mask";
import { UIUtilGeneral } from "../util/Util";
import { IDropDownAdvancedConfig } from "./DropdownAdvanced";
import { InputFileControl } from "./InputFileControlV2";
import { InputPassword } from "./InputPassword";
import { IConfigRadioList, RadioList } from "./RadioList";
import { SelectSuggest } from "./SelectSuggester";
import { SelectV2 } from "./SelectV2";

export enum Fields {
    input = "input",
    label = "label",
    selectMaterial = "selectMaterial",
    inputSuggest = "inputSuggest",
    radioList = "radioList",
    // foto = "foto",
    fotoV2 = "fotoV2",
    textArea = "textArea",
    unknown = "unknown"
}

interface IInstancesMap {
    "input": null;
    "label": null;
    "selectMaterial": SelectV2;
    "inputSuggest": SelectSuggest;
    "radioList": RadioList;
    // "foto": controlD3.InputImageControl;
    "fotoV2": InputFileControl.InputFile;
    "textArea": null;
    "unknown": unknown;
}
interface IAttributosMap {
    "input": IAtributosInput;
    "label": null;
    "selectMaterial": IAtributosSelectMaterial;
    "inputSuggest": IAtributosInputSuggest;
    "radioList": IAtributosRadioList;
    // "foto": controlD3.InputImageControl;
    "fotoV2": IAtributosFotoV2;
    "textArea": IAtributosInput;
    "unknown": IAtributosUnknown;
}
type TFieldType = keyof typeof Fields & string;

type IInputProperties = "value" | "step";
export interface IAtributosInput {
    /** @default "text" */
    type?: string;
    value?: string;
    /** @default "input-form" */
    class?: string;
    disabled?: boolean;
    size?: number;
    minlength?: number;
    maxlength?: number;
    pattern?: string;
    removeBorder?: boolean;
    min?: number | string;
    max?: number | string;
    placeholder?: string;
    valueAs?: "text" | "number" | "date";
    required?: boolean;
    step?: number | string;
    onchange?: (e: Event) => void;
    oninput?: (e: Event) => void;
    /** Se agregan a las propiedades del elemento input.property(key, val) */
    inputProperties?: { [key in IInputProperties]?: (number | string | boolean) } & {
        /** @deprecated */
        required?: boolean;
    }
    onload?: (input: TSelectionHTML<"input">) => void;
    TextAreaStopEnterPropagate?: boolean;
}
export interface IAtributosLabel {
    /** LabelText || LabelLangKey */
    text?: string;
    /** @default "titulos_base" */
    class?: string;
}
export interface IAtributosSelectMaterial<T = any, VM extends keyof T = keyof T> extends Pick<IDropDownAdvancedConfig<T, VM>, "Data" | "OnAccept" | "RecyclerView" | "ListWidth"> {
    valueMember?: VM;
    displayMember?: keyof T;
    placeholder?: string
    disabled?: boolean;
    /** Devuelve el/los valores correspondientes al valueMember del objeto en el primer argumento, devuelve los objetos en el segundo argumento
     * * Si es multiselect devuelve un arreglo */
    onChange?: (data: any, dataObj: any) => void;
    /** Devuelve el/los objetos seleccionados
     * * Si es multiselect devuelve un arreglo
     * * Si no se acualiza con ningun item seleccionado, devuelve undefined o arreglo vacio (si es multiselect) */
    onSelect?: (data: any) => void;
    removeBorder?: boolean
    multiselect?: boolean
    /** Paso por cada elemento de la lista, reemplaza la asignación de texto por default en cada elemento */
    OnStepItemListUI?: (container: d3.Selection<(HTMLDivElement | HTMLLIElement), any, HTMLElement, any>, dato: any, step: ("enter" | "update" | "exit")) => void;
    /** Reemplaza la forma de filtro por default (por default el filtro se hace con el valor de TData[DisplayMember] y no difiere entre mayúsculas y minúsculas) */
    OnChangeSearchText?: (textInput: string, dataItem: any) => boolean;
    /** Por defecto el filtro se hace de acuerdo al valor de TData[DisplayMember] y no difiere entre mayúsculas y minúsculas */
    ShowAndEnableSearchText?: boolean;
    /** Solo disponible con "multiselect" */
    ShowNSelectedInList?: boolean,
    OnChangeSearchText_GetDataValueToEval?: (dataItemObj: any) => string
    required?: boolean;
}

export interface IAtributosInputSuggest extends Pick<IDropDownAdvancedConfig<any, any>, "Data"> {
    valueMember?: string;
    displayMember?: string;
    placeholder?: string;
    disabled?: boolean,
    onChange?: (data: any, dataObj: any) => void;
    onSelect?: (data: any) => void;
    removeBorder?: boolean;
    OnStepItemListUI?: () => void;
    required?: boolean;
    Strict?: boolean
}

export interface IAtributosFotoV2 extends InputFileControl.IConfig {
    RowClase?: string;
    /** @default "base64" */
    TypeValue?: "stringSrc" | "base64" | "File"
    required?: boolean;
}
export interface IAtributosRadioList extends Omit<IConfigRadioList, keyof Pick<IConfigRadioList, "Name">> {
    ReturnOnlyValueMember?: boolean
    required?: boolean;
}
export interface IAtributosUnknown {
    required?: boolean;
}
export type IField<TData = any> = {
    [F in TFieldType]: {
        type: F;
        inputAttr?: F extends ("input" | "textArea") ? IAtributosInput : never;
        /** LabelText || LabelLangKey */
        labelText?: string;
        /** @deprecated use `labelText` instead */
        labelAttr?: IAtributosLabel;
        selectMaterialAttr?: F extends "selectMaterial" ? IAtributosSelectMaterial : never;
        inputSuggestAttr?: F extends "inputSuggest" ? IAtributosInputSuggest : never
        radioListAttr?: F extends "radioList" ? IAtributosRadioList : never;
        fotoV2Attrs?: F extends "fotoV2" ? IAtributosFotoV2 : never;
        /** @deprecated */
        values?: Array<{}>;
        onLoad?: (control: IControlCreated<F>, thisForm: FormGenerator) => void;
    }
    & (F extends "label" ? { model: string, onValidate?: (dato: unknown, control: IControlCreated<F>) => boolean; } : FieldData<TData, F>)
}[TFieldType]

type FieldData<TData, F extends TFieldType> = {
    [T in (keyof TData & string)]: {
        model: T;
        /** @returns boolean or error message */
        onValidate?: (dato: TData[T], control: IControlCreated<F>) => boolean | string;
    }
}[keyof TData & string]

type TInputPhoneAuxProps = { _SelectPhoneCode?: SelectSuggest<PhoneCode, "Code"> };
type TUnknownAuxProps = { _value?: unknown };
export type IControlCreated<K extends TFieldType> = {
    model: string
    type: K;
    /** * Elemento principal del control
     * * Por defecto la clase de error se agrega a ésta selección al momento de validar.
     * */
    selection: TSelectionHTML<
        K extends "input"
        ? "input"
        : K extends "textArea" ? "textarea" : "htmlelement"
    >;
    controlWrapper: TSelectionHTML<"htmlelement">;
    instance?: IInstancesMap[K]; // Object | controlD3.Select | RadioList | controlD3.InputImageControl;
    row: TSelectionHTML<"div">;
    // selectionExtra?: d3.Selection<HTMLElement, {}, HTMLElement, {}>;
    /** // TEMPORAL !! */
    readonly controlAttrs: IAttributosMap[K]
    // IAtributosInput | IAtributosRadioList | IAtributosSelectMaterial | IAtributosFotoV2;
}
    & ({
        "input": TInputPhoneAuxProps,
        "inputSuggest": {},
        "label": {},
        "selectMaterial": {},
        "radioList": {},
        "fotoV2": {},
        "textArea": {},
        "unknown": TUnknownAuxProps,
    })[K]

export interface IConfig<TData = any> {
    schema: Array<IField<TData>>;
    /** Por defecto en css es 200px */
    LabelMaxWidth?: number;
    /** @default true */
    LabelWrap?: boolean;
    /**
     * @param value valor del campo por el que se está iterando
     * @param field campo por el que se está iterando
     * @param dataForm datos actuales del formulario de acuerdo a los "model" que se definieron en el esquema
     * @param controlsForm mapa de controles del formulario
     * @deprecated No se recomienda su uso
     */
    Validation?: (value: any, field: keyof TData, dataForm: TData, controlsForm: Map<keyof TData, IControlCreated<TFieldType>>) => boolean | string;
    /** @deprecated No se recomienda su uso */
    BuildView?: (container: TSelectionHTML<"form">, controlsForm: Map<keyof TData, IControlCreated<TFieldType>>, form: FormGenerator<TData>) => void;
    /** Se invoca despues de asignar datos a cada control del formulario */
    OnAssignData?: (data: TData, controlsForm: Map<keyof TData, IControlCreated<TFieldType>>, form: FormGenerator<TData>, container: TSelectionHTML<"form">) => void;
    OnLoad?: (container: TSelectionHTML<"form">, controlsForm: Map<keyof TData, IControlCreated<TFieldType>>, form: FormGenerator<TData>) => void
    /** Key del "modulo" al que pertenecen los string labels */
    LangModuleKeyInContext?: string;
}
interface IDetailsRow<TData = any> {
    rowSelection?: d3.Selection<HTMLDivElement, {}, HTMLElement, null>;
    config?: IField<TData>;
}
export interface IDetailsSelectionData {
    type: Fields;
    model: string;
    selection: d3.Selection<HTMLDivElement, {}, HTMLElement, null> | any;
    controlAttrs: IAtributosInput | IAtributosRadioList | IAtributosSelectMaterial | IAtributosFotoV2;
}
export interface IPropiedadesForm<TData = any> {
    selectionSchema?: d3.Selection<HTMLFormElement, any, any, any>;
    rows?: Array<IDetailsRow<TData>>;
    // selectionData?: Array<IDetailsSelectionData>;
    // data?: TData;
    /** Data que se le asigna en un principio al formulario, el formulario no lo puede mutar
     * * Es un clon Object.Assign, por lo que las referencias pueden sea una excepción de mutación
    */
    dataOrigin?: TData;
    /** Controles creados a partir de la configuración
     * Key = model
     */
    controlsForm?: Map<keyof TData & string, IControlCreated<TFieldType>>
}

export interface PhoneCode {
    Id: string;
    Pais: string;
    Flag: string;
    Code: string;
    NDigitos?: number[];
    NativeName?: string;
}

export class FormGenerator<TData = any> {
    private props: IPropiedadesForm<TData> = {}
    private config: IConfig<TData>;

    constructor() {
        this.props.selectionSchema = d3.create("form").classed("formulario_generator", true)
        this.props.selectionSchema.node()
            .onsubmit = e => false;
        this.props.rows = [];
        this.props.controlsForm = new Map<keyof TData & string, IControlCreated<Fields>>()
    }

    private AplicaInputMask(attrs: IAtributosInput, input: HTMLInputElement, nDigits?: number[]) {
        switch (attrs.type) {
            case "email":
                UIUtilMask._ApplyEmailMask(input);
                break;
            case "phone_10A":
            case "phone2_10A":
                UIUtilMask._ApplyPhoneAMask(input, nDigits);
                break;
            case "phone_10B":
            case "phone2_10B":
                UIUtilMask._ApplyPhone10BMask(input);
                break;
            case "bank_card":
                UIUtilMask._ApplyBankCardMask(input);
                break;
        }
    }

    private BuildControls(schema: Array<IField<TData>>) {
        schema.forEach(field => {
            // >> Fix Lang context
            if (field.labelAttr?.text) {
                field.labelAttr.text = this.GetLangContext(field.labelAttr.text);
            }
            else if (field.labelText) {
                field.labelAttr = {
                    text: this.GetLangContext(field.labelText),
                }
            }

            // >> Build row
            const control = this.CreateControl(field);
            if (!this.config.BuildView) {
                this.props.selectionSchema.append(() => control.row.node())
            }
            if (field.onLoad) {
                field.onLoad(control as any, this)
            }
        })

        if (this.config.BuildView) {
            this.config.BuildView(this.props.selectionSchema, this.props.controlsForm, this);
        }
        // console.debug( "ControlesForm:", this.props.controlsForm)
    }

    private CreateControl(field: IField<TData>): IControlCreated<any> {
        type TModelReq = keyof TData & string
        const _row = d3.create<HTMLDivElement>("div")
            .classed("row", true)
            .attr("model", field.model)
        let _controlCreated: Partial<IControlCreated<TFieldType>> = {};
        let _attrs: IAtributosFotoV2 | IAtributosInput | IAtributosRadioList | IAtributosSelectMaterial;
        let _label: TSelectionHTML<"label">;
        let _controlWrapper: TSelectionHTML<"div">;
        if (/* field.type !== Fields.fotoV2 && */ field.type !== Fields.label && field.labelAttr) {
            _label = this.LabelCreate(field.labelAttr);
            _row.append(() => _label.node())
        }
        switch (field.type) {
            case Fields.label:
                _label = field.labelAttr ? this.LabelCreate(field.labelAttr) : undefined;
                _attrs = field.labelAttr;
                _controlCreated = { type: field.type, selection: _label as any, }
                if (_label) {
                    _row.append(() => _label.node());
                }
                break;
            case Fields.input:
                field.inputAttr = {
                    ...{ type: "text" },
                    ...(field.inputAttr || {})
                }
                _attrs = field.inputAttr;
                _controlCreated = this.GetInputControl(field.inputAttr, field.model) as any;
                break;
            case Fields.radioList:
                _attrs = field.radioListAttr;
                _controlCreated = this.GetRadioListControl(field.radioListAttr, field.values, field.model);
                break;
            case Fields.selectMaterial:
                _attrs = field.selectMaterialAttr;
                _controlCreated = this.GetSelectControl(field.selectMaterialAttr, _row, field.values || field.selectMaterialAttr.Data, field.model)
                break;
            case Fields.inputSuggest:
                _attrs = field.inputSuggestAttr;
                _controlCreated = this.GetInputSuggestControl(field.inputSuggestAttr, _row, field.values || field.inputSuggestAttr.Data, field.model)
                break;
            case Fields.fotoV2:
                _attrs = field.fotoV2Attrs;
                _controlCreated = this.GetFileControl(field.fotoV2Attrs, field.model);
                if (field.fotoV2Attrs.RowClase) _controlCreated.controlWrapper.classed(field.fotoV2Attrs.RowClase, true);
                break;
            case Fields.textArea:
                if (field.inputAttr.TextAreaStopEnterPropagate == null) field.inputAttr.TextAreaStopEnterPropagate = true;
                _attrs = field.inputAttr;
                _controlCreated = this.GetTextAreaControl(field.inputAttr, field.model);
                break;
        }
        if (field.type != "label") {
            _controlWrapper = _row.append("div").attr("class", "control_wrapper");
            if (_controlCreated.controlWrapper)
                _controlWrapper.append(() => _controlCreated.controlWrapper.node());
            else if (_controlCreated.selection)
                _controlWrapper.append(() => _controlCreated.selection.node());
            else
                _controlCreated.selection = _controlWrapper
        }

        this.props.controlsForm.set(field.model as TModelReq, {
            ..._controlCreated as any, // as Required<IControlCreated<any>>,
            model: field.model,
            type: field.type as any,
            row: _row,
            controlWrapper: _controlWrapper,
            controlAttrs: _attrs,
        })
        this.props.rows.push({ rowSelection: _row as any, config: field });
        return this.props.controlsForm.get(field.model as TModelReq);
    }

    private LabelCreate(attr: IAtributosLabel) {
        return d3.create("label")
            .classed(attr.class ? attr.class : "titulos_base", true)
            .style("max-width", this.config.LabelMaxWidth ? this.config.LabelMaxWidth + "px" : null)
            .text(attr.text);
    }

    // private GetLabelControl(attrs: IAtributosLabel): IControlCreated<Fields.label> {
    //     let label = d3.create<HTMLLabelElement>("label")
    //         .text(attrs.text)
    //         .classed(attrs.class ? attrs.class : "titulos_base", true);
    //     return { type: Fields.label, selection: label, instance: null, controlAttrs: attrs };
    // }

    private GetInputControl(attrs: IAtributosInput, model: string): Pick<IControlCreated<"input">, "selection" | "controlWrapper" | "_SelectPhoneCode"> {
        let cont: TSelectionHTML<"div">;
        let input: TSelectionHTML<"input">;
        let ctrlSelectPhoneCode: SelectSuggest<PhoneCode, "Code">;
        if (attrs.type == "password") {
            let inputPass = new InputPassword.InputPassword();
            cont = inputPass._ControlContainer
            input = inputPass._InputInside
                // .attr("data-model", model)
                .attr("id", model)
                .attr("autocomplete", "new-password")
                .classed("input-borde", attrs.removeBorder);
            // d3.create<InputPassword.HTMLInputPasswordElement>("input-password")
            if (attrs.disabled) inputPass._InputInside.property("disabled", attrs.disabled).classed("input-borde", attrs.removeBorder)

            if (attrs.placeholder) {
                input.node().placeholder = attrs.placeholder;
            }

            if (attrs.size) input.attr("size", attrs.size);
            if (attrs.minlength) input.attr("minlength", attrs.minlength);
            if (attrs.maxlength) input.attr("maxlength", attrs.maxlength);
            if (attrs.pattern) input.attr("pattern", attrs.pattern);

            if (attrs.disabled) input.property("disabled", attrs.disabled)

        } else {
            cont = d3.create("div").classed("input_content", true);
            input = cont.append<HTMLInputElement>("input")
                .attr("type", attrs.type)
                .attr("min", attrs.min)
                .attr("max", attrs.max)
                .attr("autocomplete", "off")
                .attr("id", model)
                .classed(attrs.class ? attrs.class : "input-form", true)
                .classed("input-borde", attrs.removeBorder);

            if (attrs.placeholder) {
                input.node().placeholder = attrs.placeholder;
            }

            if (attrs.size) input.attr("size", attrs.size);
            if (attrs.minlength) input.attr("minlength", attrs.minlength);
            if (attrs.maxlength) input.attr("maxlength", attrs.maxlength);
            if (attrs.pattern) input.attr("pattern", attrs.pattern);

            if (attrs.disabled) input.property("disabled", attrs.disabled)

            if (attrs.removeBorder == undefined) attrs.removeBorder = false;

            if (attrs.type == "currency" || attrs.type == "percent") {
                input.attr("type", "number");
                let symbolElement = cont.select<HTMLDivElement>(".input_symbol");
                if (!symbolElement.node()) {
                    symbolElement = cont.append("div").classed("input_symbol", true)
                    symbolElement.append("span");
                }
                symbolElement = symbolElement.select("span");

                switch (attrs.type) {
                    case "currency":
                        cont.classed("input_currency", true);
                        cont.classed("input_percent", false);
                        symbolElement.text("$");
                        break;
                    case "percent":
                        cont.classed("input_currency", false);
                        cont.classed("input_percent", true);
                        symbolElement.text("%");
                        break;
                }
            }
            if (attrs.type == "phone2_10A") {
                const copyPhoneNumber = (pastedNumber: string) => {
                    let numberSplit = pastedNumber.split(" ");
                    let numCode = numberSplit.filter(d => d.includes('+'))[0];
                    let phoneNum = numberSplit.filter(d => !d.includes('+')).join("");
                    phoneNum = phoneNum["replaceAll"](" ", "")["replaceAll"]("(", "")["replaceAll"](")", "")["replaceAll"]("‑", "")["replaceAll"]("-", "");
                    return {
                        numCode,
                        phoneNum
                    }
                }
                input.node().onpaste = (ev) => {
                    ev.preventDefault(); //NOTE: PreventDefaul prevents onInput & others
                    let pastedNumber = ev.clipboardData.getData("text") || "";
                    input.node().value = copyPhoneNumber(pastedNumber).phoneNum;
                    let dialCodeItm = UIUtilDialPhoneCodes._GetDialCodeItem(copyPhoneNumber(pastedNumber).numCode);
                    if (dialCodeItm) { ctrlSelectPhoneCode._valueSelect([dialCodeItm.Code]) }
                };
                cont.classed("input_dial_code", true)
                let dialCode = cont.select<HTMLDivElement>(".dial_code");
                if (!dialCode.node()) {
                    ctrlSelectPhoneCode = new SelectSuggest({
                        Parent: cont as any,
                        Data: () => UIUtilDialPhoneCodes._GetDialCodeArr(),
                        ValueMember: "Code",
                        DisplayMember: "Code",
                        ListWidth: "165px",
                        OnStepItemListUI: (container, item, step) => {
                            if (step == "enter") {
                                container.classed(UIUtilGeneral.FBoxOrientation.Vertical, true)
                                    .style("align-items", "flex-start");
                                container.append("label")
                                    .style("cursor", "pointer");
                                container.append("pre")
                                    .style("cursor", "pointer")
                                    .style("font-size", "var(--fontsize_me4)");
                            }
                            container.select("label:nth-child(1)").text(item.Code);
                            container.select("pre").text((item.NativeName ? item.NativeName : item.Pais));
                        },
                        OnSelect: (datos) => {
                            if (datos.length) {
                                this.AplicaInputMask(attrs, input.node(), datos[0]?.NDigitos || []);
                            }
                        },
                        ToApplyMask: (input) => {
                            UIUtilMask._ApplyDialCodeMask(input);
                        },
                        StrictSelect: true
                    })
                    ctrlSelectPhoneCode._controlSelection.classed("dial_code", true)
                }
                ctrlSelectPhoneCode._controlSelection.select<HTMLInputElement>("input").node().onpaste = (ev) => {
                    ev.preventDefault(); //NOTE: PreventDefaul prevents onInput & others
                    let pastedNumber = ev.clipboardData.getData("text") || "";
                    ctrlSelectPhoneCode._valueSelect([copyPhoneNumber(pastedNumber).numCode]);
                    input.node().value = copyPhoneNumber(pastedNumber).phoneNum;
                }
                cont.select("input").raise(); // Para que el drpdwn aparezca antes del numero
            }
            else if (attrs.type == "checkbox") {
                cont.style("width", "auto")
                input.style("width", "auto");
                setTimeout(() => {
                    d3.select(cont.node().parentElement).style("width", "auto"); // .control_wrapper
                    d3.select(cont.node().parentElement.parentElement) // .row
                        .style("align-items", "center")
                });
            }
            this.AplicaInputMask(attrs, input.node());
        }

        if (attrs.inputProperties) {
            for (let key in attrs.inputProperties) {
                input.property(key, attrs.inputProperties[key]);
            }
        }
        if (attrs.required !== undefined) input.property("required", attrs.required);
        if (attrs.step !== undefined) input.attr("step", attrs.step);
        if (attrs.value !== undefined) input.attr("value", attrs.value);
        if (attrs.onchange !== undefined) input.node().onchange = attrs.onchange;
        if (attrs.oninput !== undefined) input.node().oninput = attrs.oninput;
        if (attrs.onload) attrs.onload(input)

        input.node().addEventListener("blur", () => this.ValideItemControlByModel(model as any))

        return { selection: input, controlWrapper: cont as any, _SelectPhoneCode: ctrlSelectPhoneCode }
    }

    private GetTextAreaControl(attrs: IAtributosInput, model: string): Pick<IControlCreated<any>, "selection"> {
        attrs = attrs || {};
        // let wrapper = d3.create("div").attr("class", "input_content");
        let textArea = d3.create<HTMLTextAreaElement>("textarea")
            .attr("id", model)
            .style("width", "100%")
            // .classed(attrs.class ? attrs.class : "input-form", true)
            .classed("input-borde", attrs.removeBorder);

        if (attrs.disabled) textArea.property("disabled", attrs.disabled)
        if (attrs.maxlength) textArea.attr("maxlength", attrs.maxlength)
        if (attrs.minlength) textArea.attr("minlength", attrs.minlength)
        if (attrs.required !== undefined) textArea.property("required", attrs.required);

        if (attrs.removeBorder == undefined) attrs.removeBorder = false;
        if (attrs.TextAreaStopEnterPropagate) {
            textArea.node().onkeydown = (e) => {
                if (e.key == "Enter") {
                    e.stopPropagation()
                }
            }
        }
        textArea.node().addEventListener("blur", () => this.ValideItemControlByModel(model as any))

        return { selection: textArea as any }
    }

    private GetSelectControl(attrs: IAtributosSelectMaterial, contenedor: any, values: any, model: string): Pick<IControlCreated<any>, "selection" | "instance"> {
        const selectCtrl = new SelectV2<any, any, ("monoselect" | "multiselect")>({
            Type: attrs.multiselect ? "multiselect" : "monoselect",
            Parent: contenedor,
            Data: values,
            ValueMember: attrs.valueMember,
            DisplayMember: attrs.displayMember,
            Placeholder: attrs.placeholder,
            Disabled: attrs.disabled,
            OnChange: attrs.onChange,
            OnSelect: attrs.onSelect,
            RemoveBorder: attrs.removeBorder,
            ShowAndEnableSearchText: attrs.ShowAndEnableSearchText,
            ShowNSelectedInList: attrs.ShowNSelectedInList,
            OnChangeSearchText_Validator: attrs.OnChangeSearchText,
            OnChangeSearchText_GetDataValueToEval: attrs.OnChangeSearchText_GetDataValueToEval,
            OnStepItemListUI: attrs.OnStepItemListUI,
            OnAccept: attrs.OnAccept,
            RecyclerView: attrs.RecyclerView,
            ListWidth: attrs.ListWidth,
        });
        // select_tag._Configuracion(contenedor, values, attrs.valueMember, attrs.displayMember, model, attrs.placeholder, attrs.disabled, attrs.onChange, attrs.onSelect, attrs.removeBorder, attrs.multiselect, attrs.ShowAndEnableSearchText, attrs.ShowNSelectedInList, attrs.OnChangeSearchText, attrs.OnStepItemListUI, attrs.OnChangeSearchText_GetDataValueToEval, attrs.OnAccept, attrs.RecyclerView, attrs.ListWidth)
        let controlSelection = selectCtrl._controlSelection
        // select.select("input").attr("data-model", model);
        if (attrs.disabled) controlSelection.select("input").property("disabled", attrs.disabled)
        controlSelection.attr("required", attrs.required ? "" : null)
        selectCtrl._InputTextSelection.node().addEventListener("blur", () => this.ValideItemControlByModel(model as any))
        selectCtrl._AddOnChangeListener(() => this.ValideItemControlByModel(model as any))
        return { selection: controlSelection as any, instance: selectCtrl };
    }

    private GetInputSuggestControl(attrs: IAtributosInputSuggest, contenedor: any, values, model: string): Pick<IControlCreated<any>, "selection" | "instance"> {
        let inputSuggest_tag = new SelectSuggest(null);
        let inputSuggest = inputSuggest_tag._Configuracion(contenedor, values, attrs.valueMember, attrs.displayMember, attrs.placeholder, attrs.disabled, attrs.onChange, attrs.onSelect, attrs.removeBorder, attrs.OnStepItemListUI, attrs.Strict)
        if (attrs.disabled) inputSuggest.select("input").property("disabled", attrs.disabled);
        inputSuggest.attr("required", attrs.required ? "" : null);
        return { selection: inputSuggest as any, instance: inputSuggest_tag }
    }

    private GetRadioListControl(attrs: IAtributosRadioList, data: {}[], model: string): Pick<IControlCreated<any>, "selection" | "instance"> {
        let radioList = new RadioList({ Data: data || attrs.Data, ValueMember: attrs.ValueMember, DisplayMember: attrs.DisplayMember, Name: model, Disabled: attrs.Disabled, OnChange: attrs.OnChange, Direction: attrs.Direction, OnStepItemUI: attrs.OnStepItemUI });
        let cont_radio = radioList._ContainerSelection;
        cont_radio.attr("required", attrs.required ? "" : null)
        return { selection: cont_radio as any, instance: radioList };
    }

    private GetFileControl(attr: IAtributosFotoV2, model: string): Pick<IControlCreated<any>, "selection" | "instance" | "controlWrapper"> {
        if (!attr.TypeValue) attr.TypeValue = "base64";
        let fotoControl = new InputFileControl.InputFile(attr);
        fotoControl._ImgSelection.attr("data-model", model);
        let selection = fotoControl._ControlSelection.select<HTMLElement>(".foto_control");
        selection.attr("required", attr.required ? "" : null)
        return { selection: selection, instance: fotoControl, controlWrapper: fotoControl._ControlSelection as any, };
    }

    // public met_ResetControlsValues() {
    //     //let data = this.props.data;
    //     // for (let prop in data) {
    //     this.props.selectionSchema.node().reset();

    //     this.props.controlsForm.forEach((control, prop) => {
    //         // let control = this.props.controlsForm.get(prop)
    //         if (control) {
    //             control.selection?.classed("input_err", false)
    //             switch (control.type) {
    //                 case Fields.input:
    //                     // (control.selection.node() as HTMLInputElement).// .property("value", "")
    //                     break;
    //                 case Fields.radioList:
    //                     (<RadioList>control.instance).met_ResetCheck();
    //                     break;
    //                 case Fields.selectMaterial:
    //                     (<Select>control.instance).met_ResetSelect();
    //                     break;
    //                 case Fields.fotoV2:
    //                     (<InputFileControl.InputFile>control.instance).met_Reset();
    //                     break;
    //                 case Fields.textArea:
    //                     // control.selection.property("value", "")
    //                     break;
    //             }
    //         }
    //     });
    // }

    /** Obtiene los valores de los controles del formulario y se los aplica a Data */
    private GetValuesSelections(data: TData = <TData>{}) {
        // this.props.selectionData.forEach(sel => {
        this.props.controlsForm.forEach((_, model) => {
            data[model] = this.GetModelValue(model, data);
        })
        return data;
    }

    private GetLangContext(stringKey: string) {
        if (this.config.LangModuleKeyInContext) {
            return (UIUtilLang._GetUIString(this.config.LangModuleKeyInContext, stringKey) || stringKey);
        }
        return stringKey;
    }

    private GetModelValue<Model extends keyof TData & string>(model: Model, data: TData = <TData>{}): TData[Model] {
        let valueFinal = undefined;
        const sel = this.props.controlsForm.get(model)

        switch (sel.type) {
            case Fields.label:
                valueFinal = data[model];
                break;
            case Fields.unknown:
                valueFinal = (sel as IControlCreated<"unknown">)._value
                break;
            case Fields.input:
                let inputSel = (<d3.Selection<HTMLInputElement, any, any, any>>sel.selection);
                if (inputSel.attr("type") === "checkbox" || inputSel.attr("type") === "radio")
                    valueFinal = inputSel.property("checked");
                else {
                    let inputConfig = (<IAtributosInput>sel.controlAttrs);
                    if ((inputConfig.type == "number" || inputConfig.type == "currency" || inputConfig.type == "percent") && inputConfig.valueAs == undefined) {
                        valueFinal = inputSel.node().valueAsNumber;
                    } else {
                        if (inputConfig.valueAs == undefined || inputConfig.valueAs == "text") {
                            valueFinal = inputSel.node().value; // ("value");
                        }
                        else if (inputConfig.valueAs == "number") {
                            valueFinal = inputSel.node().valueAsNumber;
                        }
                        else if (inputConfig.valueAs == "date") {
                            valueFinal = inputSel.node().valueAsDate;
                        }

                        if (inputConfig.type === "phone2_10A") {
                            let dialCodeText: string;
                            //let dialCodeCont = d3.select(inputSel.node().parentElement).select(".dial_code");
                            const selectCodePhone = (sel as IControlCreated<"input">)._SelectPhoneCode;
                            if (selectCodePhone) {
                                dialCodeText = selectCodePhone._uniqueVMSelected;
                            }
                            valueFinal = (dialCodeText && valueFinal) ? (dialCodeText + " " + valueFinal) : valueFinal;
                        }
                    }
                }
                break;
            case Fields.radioList:
                if ((<RadioList>sel.instance)._ItemSelected) {
                    let attrsRadioL = (<IAtributosRadioList>sel.controlAttrs);
                    let instance = (<RadioList>sel.instance);
                    if (attrsRadioL.ReturnOnlyValueMember) {
                        valueFinal = instance._ItemSelectedValue;
                    } else {
                        valueFinal = instance._ItemSelected;
                    }
                }
                break;
            case Fields.selectMaterial:
                if ((<IAtributosSelectMaterial>sel.controlAttrs).multiselect) {
                    valueFinal = (<SelectV2>sel.instance)._dataValueMemberSelected; // (<d3.Selection<HTMLElement, any, any, any>>sel).select("input").datum();
                } else {
                    valueFinal = (<SelectV2>sel.instance)._dataValueMemberSelected[0];
                }
                break;
            case Fields.inputSuggest:
                if ((<IAtributosInputSuggest>sel.controlAttrs).Strict) {
                    valueFinal = (<SelectSuggest>sel.instance)._uniqueVMSelected;
                } else {
                    valueFinal = (<SelectSuggest>sel.instance)._inputValue
                }
                break;
            case Fields.fotoV2:
                if ((<IAtributosFotoV2>sel.controlAttrs).TypeValue == "stringSrc") {
                    valueFinal = (<InputFileControl.InputFile>sel.instance)._SrcFromImg;
                }
                else if ((<IAtributosFotoV2>sel.controlAttrs).TypeValue == "base64") {
                    valueFinal = (<InputFileControl.InputFile>sel.instance)._Base64;
                }
                else if ((<IAtributosFotoV2>sel.controlAttrs).TypeValue == "File") {
                    valueFinal = (<InputFileControl.InputFile>sel.instance)._File;
                }
                break;
            case Fields.textArea:
                const textArea = (sel.selection as TSelectionHTML<"textarea">);
                valueFinal = textArea.node().value;
                break;
        }
        return valueFinal
    }

    private SetModelValue<Model extends keyof TData & string>(model: Model, value: TData[Model]) {
        const control = this.props.controlsForm.get(model);
        // const value = ;
        let valueIsValid = (value !== undefined && value !== null);
        switch (control.type) {
            case Fields.input:
                let inputSel = (control.selection as TSelectionHTML<"input">);
                if (inputSel.attr("type") === "checkbox" || inputSel.attr("type") === "radio") {
                    control.selection.property("checked", value);
                } else {
                    let inputConfig = (<IAtributosInput>control.controlAttrs);
                    if (inputConfig.type === "phone2_10A") {
                        if (value) {
                            let values = (value as string).split(" ")
                            let [code, number] = values;
                            if (code && !code.includes('+')) {
                                number = code;
                                code = null;
                            }
                            control.selection.property("value", number);
                            const selectPhoneCode = (control as IControlCreated<"input">)._SelectPhoneCode;
                            const itemCode = UIUtilDialPhoneCodes._GetDialCodeItem(code);
                            //control.controlWrapper.select(".input_dial_code").select(".dial_code").select<HTMLSpanElement>("span").text(`(${code ? code : '+##'})`);
                            if (selectPhoneCode && itemCode)
                                selectPhoneCode._valueSelect([itemCode.Code])
                            this.AplicaInputMask(control.controlAttrs as IAtributosInput, inputSel.node(), itemCode ? itemCode.NDigitos : []);
                            break;
                        } else {
                            let code = UIUtilGeneral._GetLocationDialCode();
                            const selectPhoneCode = (control as IControlCreated<"input">)._SelectPhoneCode;
                            const itemCode = UIUtilDialPhoneCodes._GetDialCodeItem(code);
                            if (selectPhoneCode && itemCode)
                                selectPhoneCode._valueSelect([itemCode.Code])
                            this.AplicaInputMask(control.controlAttrs as IAtributosInput, inputSel.node(), itemCode ? itemCode.NDigitos : []);
                            break;
                        }
                    } else {
                        control.selection.property("value", value == null ? '' : value);
                    }
                }
                this.AplicaInputMask(control.controlAttrs as IAtributosInput, inputSel.node());
                break;
            case Fields.radioList:
                if (valueIsValid) {
                    (<RadioList>control.instance)._SetValueSelected(value);
                }
                else {
                    (<RadioList>control.instance)._ResetCheck();
                }
                break;
            case Fields.selectMaterial:
                (<SelectV2>control.instance)._valueSelect((valueIsValid ? value : null));
                break;
            case Fields.inputSuggest:
                (<SelectSuggest>control.instance)._valueSelect((valueIsValid ? (value as any) : null))
                break;
            case Fields.fotoV2:
                (<InputFileControl.InputFile>control.instance)._UrlResource = (valueIsValid ? String(value) : null);
                break;
            case Fields.textArea:
                control.selection.property("value", value);
                break;
        }
    }

    /** Dato que se ingresa al formulario de forma externa, el formulario no puede mutar éste dato */
    public get _DataOrigin() {
        return this.props.dataOrigin;
    }

    /** Obtiene el objeto con los valores actuales de los controles del formulario
     * * NOTA: Modifica el objeto que se asignó al Formulario
    */
    public get _Data() {
        return this.GetValuesSelections(Object.assign({}, this.props.dataOrigin)); // this.props.data as TData);
    }
    /** Obtiene un objeto nuevo (clonado del asignado al formulario) con los valores actuales de los controles del formulario
     * * NOTA: No modifica el objeto que se asignó al Formulario
    */
    public get _OnlyFrmData() {
        return this.GetValuesSelections();// <TData>JSON.parse(JSON.stringify(this.props.data)));
    }
    public get _Form() {
        return this.props.selectionSchema;
    }

    public get _ControlsData() {
        return this.props.controlsForm
    }

    /**
     *
     * @param config
     * @param data
     * @param claseFormulario
     * @param inicializarCtrlsData Los controles del formulario toman los valores correspondientes del data ?
     * @returns
     */
    public _Crear(config: IConfig<TData>, data?: TData, claseFormulario: string = "form_modalbase", inicializarCtrlsData = true) {
        this.props.selectionSchema.classed(claseFormulario, true);
        this.config = config;
        data = data || <any>{};
        if (config.LabelWrap === false)
            this.props.selectionSchema.classed("lbl_no_wrap", true);
        this.BuildControls(config.schema)
        if (this.config.OnLoad) {
            this.config.OnLoad(this.props.selectionSchema, this.props.controlsForm, this);
        }
        if (inicializarCtrlsData) {
            this._AsignaData(data);
        }
        return this;
    }

    private isFieldRowHidden(row: d3.Selection<HTMLElement, any, any, any>): boolean {
        let rowProperties = row.node().getBoundingClientRect();
        // NOTE: getBoundingClientRect Tiene una propiedad que es una función
        let isVisible = [
            rowProperties.bottom, rowProperties.height, rowProperties.left, rowProperties.right, rowProperties.top, rowProperties.width, rowProperties.x, rowProperties.y
        ].some(d => d != 0)
        return (row.classed("hide") || !isVisible)
    }

    /** Ejecuta la validación que se define en la configuración de formulario
     * @returns isValid
    */
    public _GetIsValidForm(): boolean {
        let isFormValid = true;
        let datosForm = this.GetValuesSelections();
        this.props.selectionSchema.classed("form_verified", true);

        for (const [_prop, control] of this.props.controlsForm) {
            const isPropValid = this.ValideItemControl(control, datosForm);
            if (isFormValid && !isPropValid) {
                isFormValid = isPropValid;
            }
        }

        if (!isFormValid)
            (this.props.selectionSchema.select<HTMLElement>("input:invalid").node() || this.props.selectionSchema.select<HTMLElement>(".input_err").node())?.focus();
        return isFormValid;
    }

    private ValideRequired(value) {
        return (typeof value === "number") ? (!isNaN(value)) : (value != null && (value + "").trim() != "");
    }

    private ValideItemControlByModel(model: keyof TData & string): boolean {
        const control = this._GetModelControl(model)
        return this.ValideItemControl(control)
    }

    private ValideGetInputErrorMsg(inputP: HTMLInputElement | HTMLTextAreaElement): string {
        const input: HTMLInputElement = inputP as any
        let errorMessage: string
        const validity = input.validity
        if (validity.valid) return
        if (validity.tooShort) {
            errorMessage = _L("control.form.min_length", "<b>" + input.minLength + "</b>");
        } else if (validity.tooLong) {
            errorMessage = _L("control.form.max_length", "<b>" + input.maxLength + "</b>");
        } else if (validity.rangeOverflow) {
            errorMessage = _L("control.form.range_overflow", "<b>" + input.max + "</b>");
        } else if (validity.rangeUnderflow) {
            errorMessage = _L("control.form.range_underflow", "<b>" + input.min + "</b>");
        }
        return errorMessage
    }

    private ValideItemControl(control: IControlCreated<TFieldType>, datosForm?: TData): boolean {
        const prop = control.model as keyof TData & string
        const value = datosForm
            ? (<TData>datosForm)[prop]
            : this.GetModelValue(prop);
        if (!datosForm) {
            datosForm = this.GetValuesSelections();  // FIXME REMOVER CUANDO SE QUITE this.config.Validation
        }
        let inputConfig = (<IAtributosInput>control.controlAttrs) || {};
        let isPropValid = true;
        /* PARA TELEFONOS CON CÓDIGO */
        let isDialCodeErr = false;
        let selectCodePhone: SelectSuggest<PhoneCode, "Code">;
        let message: string

        // if (!control.row.classed("hide")) {
        if (!this.isFieldRowHidden(control.row)) {
            switch (control.type) {
                case Fields.input || Fields.textArea:
                    const inputControl = (control.selection.node() as (HTMLInputElement | HTMLTextAreaElement))
                    inputControl.setCustomValidity("");
                    if (inputConfig.type == "email") {
                        isPropValid = (typeof value == "string") && UIUtilFormat._EMAILREGEX.test(value as string);
                        if (!isPropValid) {
                            message = _L("control.form.invalid_fmt")
                        }
                    }
                    if (inputConfig.type == "phone2_10A") {
                        selectCodePhone = (control as IControlCreated<"input">)._SelectPhoneCode;
                        if (selectCodePhone && !selectCodePhone._validity.valid) {
                            isPropValid = false;
                            isDialCodeErr = true;
                            message = _L("control.form.invalid_fmt")
                        }
                    }
                    if (isPropValid && !inputControl.validity.valid) {
                        isPropValid = false;
                        message = this.ValideGetInputErrorMsg(inputControl)
                    }
                    break;
                // @ts-expect-error
                case Fields.inputSuggest:
                    if (!control.controlAttrs?.required && (<SelectSuggest>control.instance)._validity.inputHasValue && !(<SelectSuggest>control.instance)._validity.valid) {
                        isPropValid = false;
                        message = _L("control.form.invalid_fmt")
                    }
                // Se deja pasar para Restantes
                // Alternativa poner sentencia del default tambien en Fields.inputSuggest
                default:
                    if (control.controlAttrs?.required && !this.ValideRequired(value)) {
                        isPropValid = false;
                        message = _L("control.form.required")
                    }
                    break;
            }

            if (control.selection?.node().hasAttribute("required") && !this.ValideRequired(value)) {
                isPropValid = false;
                message = _L("control.form.required")
            }

            if (isPropValid && this.config.Validation) {
                let valueIsValid = (value !== undefined && value !== null);
                const valideRes = this.config.Validation((valueIsValid ? value : null), prop, datosForm, this.props.controlsForm);
                if (typeof valideRes == "boolean")
                    isPropValid = valideRes
                else if (valideRes.trim()) {
                    isPropValid = false
                    message = valideRes
                }
            }
            if (isPropValid) {
                type FieldReq = IField<TData> & { type: "input" /* fix type */ }
                const { onValidate } = (this.config.schema.find(d => d.model == prop) || {}) as FieldReq
                if (onValidate) {
                    const valideRes = onValidate(value, control as any);
                    if (typeof valideRes == "boolean")
                        isPropValid = valideRes
                    else if (valideRes.trim()) {
                        isPropValid = false
                        message = valideRes
                    }
                }
            }
        }

        if (control.type == Fields.input && inputConfig.type == "phone2_10A") {
            control.controlWrapper.select(".input_dial_code .dial_code")
                .classed("input_err", isDialCodeErr);

            control.selection.classed("input_err", !isPropValid);
            // if (inputConfig.type != "phone2_10A") {
            // const input = (control.selection.node() as HTMLInputElement);
            // input.setCustomValidity(isPropValid ? "" : (input.validationMessage || "invalid"));
            // }
        } else
            control.selection.classed("input_err", !isPropValid);

        let failMessageNode = control.row.select<HTMLDivElement>(".control_wrapper .failmessage").node();
        if (message) {
            if (!failMessageNode) {
                failMessageNode = control.row.select(".control_wrapper").append("div")
                    .attr("class", "failmessage")
                    .node()
            }
            failMessageNode.innerHTML = message
        } else if (!message && failMessageNode) {
            failMessageNode.remove()
        }
        return isPropValid
    }

    public _RemoveValidationStyle() {
        this.props.selectionSchema.classed("form_verified", true);
        for (const [_, control] of this.props.controlsForm) {
            control.selection.classed("input_err", false);
        }

    }

    /** Asigna los values iniciales a los controles del formulario y actualiza el dataOrigin interno del formulario */
    public _AsignaData(data: TData) {
        /// this.props.data = data;
        let auxData = Object.assign({}, data);
        this.props.dataOrigin = Object.assign({}, data);
        // if (Array.isArray(data) === false) {
        // for (let prop in (<TData>data)) {
        this.props.controlsForm.forEach((_, model) => {
            // let control = this.props.controlsForm.get(prop)
            // if (control) {
            // let value = (<TData>this.props.data)[prop];
            const value = (<TData>auxData)[model]
            this.SetModelValue(model, value)
            // if (this.config.Validation) {
            //     let isValid = this.config.Validation((valueIsValid ? value : null), prop, data as TData, this.props.controlsForm);
            //     control.selection.classed("input_err", !isValid);
            // }
            // }
        })
        // }
        if (this.config.OnAssignData) {
            this.config.OnAssignData(data, this.props.controlsForm, this, this.props.selectionSchema);
        }
    }

    public _GetModelControl<Tipo extends TFieldType>(model: keyof TData & string): IControlCreated<Tipo> {
        const control = this.props.controlsForm.get(model)
        return control as any;
    }

    public _SetModelValue<Model extends keyof TData & string>(model: Model, value: TData[Model]): this {
        this.SetModelValue(model, value)
        return this
    }

    public _GetModelValue<Model extends keyof TData & string>(model: Model): TData[Model] {
        return this.GetModelValue(model)
    }

    public get _IsPreviewMode() {
        return this.props.selectionSchema.classed("preview_mode");
    }

    // public set _IsPreviewMode(val: boolean) {
    //     this._PreviewMode(val);
    // }

    public _PreviewMode(previewMode: boolean): this {
        this.props.selectionSchema.classed("preview_mode", previewMode);

        if (previewMode) this._RemoveValidationStyle();

        this.props.controlsForm.forEach((control, field) => {
            switch (control.type) {
                case "selectMaterial":
                    const inputSelect = control.selection.select<HTMLInputElement>("input");
                    this._TabIndexMode(inputSelect, previewMode);
                    if (previewMode) inputSelect.node().blur();
                    break;
                case "radioList":
                    const inputsRadios = control.selection.selectAll<HTMLInputElement, any>("input");
                    this._TabIndexMode(inputsRadios, previewMode);
                    if (previewMode) inputsRadios.node().blur();
                    break;
                case "fotoV2":
                    const inputFoto = control.selection.select<HTMLInputElement>("input");
                    this._TabIndexMode(inputFoto, previewMode);
                    if (previewMode) inputFoto.node().blur();
                    break;
                default:
                    const input = (control.selection as TSelectionHTML<"input">);
                    this._TabIndexMode(input, previewMode);
                    if (previewMode) input.node().blur();
                    break;
            }
            if (control.type == Fields.input && (control.controlAttrs as IAtributosInput).type == "date") {
                const input = (control.selection as TSelectionHTML<"input">).node();
                input.type = (previewMode ? "text" : "date");
                input.readOnly = previewMode;
            }

            /* if (control.type == Fields.radioList) {
                let radioList = (control.instance as RadioList)
                radioList.prop_ContainerNode.childNodes.forEach((child: HTMLDivElement, i, childs) => {
                    let item = d3.select(child);
                    let input = child.querySelector("input");
                    item.attr("checked", (previewMode && input.checked) ? "" : null);
                })
            } */
        })
        return this;
    }

    public _TabIndexMode(formElement: TSelectionHTML<any>, readOnly: boolean) {
        formElement.attr("tabindex", readOnly ? "-1" : null);
    }
}
